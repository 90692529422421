<template>
  <div class="task_logs">
    <div class="public-breadcrumb">
      <router-link style="color: #6e6b7b" to="/">{{
        $t("devices.Welcome")
      }}</router-link>
      > {{ $t("taskLog.logs") }} >
      <span style="color: #3461ff">{{ $t("taskLog.Task logs") }}</span>
    </div>
    <div class="table_set">
      <div class="select_filter">
        <div>
          <span class="text"
            >{{ $t("taskLog.Records") }}:{{ pageParams.total }}</span
          >
          <el-select
            v-model="pageParams.storeType"
            @change="getTaskScheduleLogListReq"
            size="small"
          >
            <el-option
              v-for="item in storeQueryTypeList"
              :key="item.dictValue"
              :label="item.dictName"
              :value="item.dictValue"
            />
          </el-select>
        </div>
      </div>
      <el-table
        ref="tableRows"
        :data="tableData"
        style="width: 100%"
        :header-cell-style="{ 'text-align': 'center' }"
        :cell-style="{ 'text-align': 'center' }"
        class="table-topstyle"
        @select-all="handleSelectAll"
        @sort-change="handleSortChange"
      >
        <el-table-column type="selection" width="55">
          <el-table-column type="selection" />
        </el-table-column>
        <el-table-column :label="$t('taskLog.Store Name')">
          <el-table-column prop="storeName" min-width="150">
            <template slot="header">
              <el-input
                clearable
                v-model="pageParams.storeName"
                size="mini"
                @input="getTaskScheduleLogListReq"
              />
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          :label="$t('taskLog.Task Name')"
          prop="taskName"
          sortable="custom"
        >
          <el-table-column prop="taskName" min-width="150">
            <template slot="header">
              <el-input
                clearable
                v-model="pageParams.taskName"
                size="mini"
                @input="getTaskScheduleLogListReq"
              />
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column :label="$t('taskLog.Task Type')">
          <el-table-column prop="taskType" min-width="150">
            <template slot="header">
              <el-select
                v-model="pageParams.taskType"
                size="mini"
                placeholder=""
                clearable
                @change="getTaskScheduleLogListReq"
              >
                <el-option
                  v-for="item in taskTypeList"
                  :key="item.codeId"
                  :label="item.codeName"
                  :value="item.codeId"
                />
              </el-select>
            </template>
            <template slot-scope="scope">
              <span
                class="table-cell__task-type"
              >
                <img
                  v-if="scope.row.taskType === 1207"
                  class="table-cell__task-type-icon"
                  src="@/assets/img/ic_switch_temp.png"
                />
                <img
                  v-if="scope.row.taskType === 1206"
                  class="table-cell__task-type-icon"
                  src="@/assets/img/ic_layout.png"
                />
                <img
                  v-if="scope.row.taskType === 1203"
                  class="table-cell__task-type-icon"
                  src="@/assets/img/ic_led.png"
                />
                <img
                  v-if="scope.row.taskType === 1204"
                  class="table-cell__task-type-icon"
                  src="@/assets/img/ic_switch_page.png"
                />
                <img
                  v-if="scope.row.taskType === 1205"
                  class="table-cell__task-type-icon"
                  src="@/assets/img/ic_nfc.png"
                />
                <span class="table-cell__task-type-name">{{scope.row.taskTypeDesc}}</span>
              </span>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          :label="$t('taskLog.Begin time')"
          prop="startDate"
          sortable="custom"
        >
          <el-table-column prop="startDate" min-width="220">
            <template slot="header">
              <el-date-picker
                clearable
                v-model="pageParams.startTime"
                type="daterange"
                align="center"
                size="mini"
                class="intergation_picker"
                unlink-panels
                range-separator="-"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                @change="getTaskScheduleLogListReq"
              />
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          :label="$t('taskLog.End time')"
          prop="endDate"
          sortable="custom"
        >
          <el-table-column prop="endDate" min-width="220">
            <template slot="header">
              <el-date-picker
                clearable
                v-model="pageParams.endTime"
                type="daterange"
                align="center"
                size="mini"
                class="intergation_picker"
                unlink-panels
                range-separator="-"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                @change="getTaskScheduleLogListReq"
              />
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          :label="$t('taskLog.Duration(m)')"
          prop="duration"
          sortable="custom"
        >
          <el-table-column prop="duration" width="120" />
        </el-table-column>
        <el-table-column :label="$t('taskLog.Status')">
          <el-table-column prop="stateDesc" width="150">
            <template slot="header">
              <el-select
                v-model="pageParams.state"
                placeholder=""
                size="mini"
                clearable
                @change="getTaskScheduleLogListReq"
              >
                <el-option
                  v-for="item in stateList"
                  :key="item.codeId"
                  :label="item.codeName"
                  :value="item.codeId"
                />
              </el-select>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          :label="$t('taskLog.Processed')"
          prop="processeds"
          sortable="custom"
        >
          <el-table-column prop="processeds" width="200">
            <template slot="header">
              <div style="display: flex; align-items: center">
                <el-input
                  clearable
                  v-model="pageParams.startProcessed"
                  size="mini"
                  style="margin-right: 5px"
                  @input="getTaskScheduleLogListReq"
                />
                <el-input
                  clearable
                  v-model="pageParams.endProcessed"
                  size="mini"
                  style="margin-left: 5px"
                  @input="getTaskScheduleLogListReq"
                />
              </div>
            </template>
            <template slot-scope="scope">{{ scope.row.processeds || 0}}</template>
          </el-table-column>
        </el-table-column>
        <el-table-column :label="$t('taskLog.Errors')" prop="errors" sortable="custom">
          <el-table-column prop="errors" width="200">
            <template slot="header">
              <div style="display: flex; align-items: center">
                <el-input
                  clearable
                  v-model="pageParams.startError"
                  size="mini"
                  style="margin-right: 5px"
                  @input="getTaskScheduleLogListReq"
                />
                <el-input
                  clearable
                  v-model="pageParams.endError"
                  size="mini"
                  style="margin-left: 5px"
                  @input="getTaskScheduleLogListReq"
                />
              </div>
            </template>
            <template slot-scope="scope">
              {{ scope.row.errors || 0}}
              <i
                class="el-icon-search integation_serch"
                @click="quertError(scope.row)"
              />
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
      <!-- <div class="d-flex justify-content-between flex-wrap b_pag">
        <div class="d-flex align-items-center">
          <span class="text-nowrap">{{$t("pagination.Display")}}</span>
          <el-select
            v-model="pageParams.size"
            @change="getTaskScheduleLogListReq"
          >
            <el-option
              v-for="item in ['10','20','50','100','200','500','1000']"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
          <span class="text-nowrap">{{$t("pagination.Strip")}}</span>
        </div>
        <el-pagination
          background
          :page-size.sync="pageParams.size"
          :current-page.sync="pageParams.current"
          prev-text="Previous"
          next-text="Next"
          layout="prev, pager, next"
          :total="pageParams.total"
          @size-change="getTaskScheduleLogListReq"
          @current-change="getTaskScheduleLogListReq"
        />
      </div> -->
      <pagination :tableInfo="pageParams" @changePage="changePage" />
    </div>
    <!-- table -->
    <errors-log-dialog ref="errorsLogDialogRef" />
  </div>
</template>
<script>
import errorsLogDialog from "@/views/logs/integrations/dialog/errorsLog.vue";
import { commonStore } from "@/store/global-select-store";

import modelUi from "@/views/taskManagement/taskManger/utils/modelUi.js";

import { getCodeList } from "@/libs/api/taskManger.js";

import {
  parseResp,
  parseError,
} from "@/views/taskManagement/taskManger/utils/requestUtil.js";
import { getTaskScheduleLogList } from "@/libs/api/logManger";

import { getStoreId } from "@/views/taskManagement/taskManger/utils/cacheUtil.js";
import pagination from "@/components/pagination";

export default {
  components: {
    errorsLogDialog,
    pagination,
  },
  data() {
    return {
      commonStore,
      stateList: [],
      taskTypeList: [],
      storeQueryTypeList: [
        {
          dictValue: 2,
          dictName: this.$t("ThisStore"),
        },
        {
          dictValue: 1,
          dictName: this.$t("ZimenStore"),
        },
        {
          dictValue: 0,
          dictName: this.$t("AllStore"),
        },
      ],
      leng: "",
      tableData: [],
      pageParams: {
        current: 1,
        size: 10,
        total: 20,
        storeType: 2,
        storeName: "",
        taskName: "",
        taskType: "",
        state: "",
        startTime: null,
        endTime: null,
        startProcessed: "",
        endProcessed: "",
        startError: "",
        endError: "",
        orders: [],
      },
    };
  },

  created() {
    commonStore.setInjectCallBack(this.initPage);
    // this.getTaskScheduleLogListReq()

    // this.getStateListReq()
    // this.getTaskTypeListReq()
    this.initPage();
  },
  computed: {
    language() {
      return this.$store.state.appConfig.language;
    },
  },
  watch: {
    language(val) {
      if (val) this.initPage();
    },
  },
  methods: {
    initPage() {
      this.getTaskScheduleLogListReq();
      this.getStateListReq();
      this.getTaskTypeListReq();
    },
    changePage() {
      this.getTaskScheduleLogListReq();
    },
    handleSortChange(v1) {
      const { prop } = v1;
      const { order } = v1;
      let orderStr = "";
      if (order === "descending") {
        orderStr = "desc";
      }
      if (order === "ascending") {
        orderStr = "asc";
      }

      let keyName = "";
      if (prop === "taskName") {
        keyName = "task_name";
      }
      if (prop === "startDate") {
        keyName = "start_date";
      }
      if (prop === "endDate") {
        keyName = "end_date";
      }
      if (prop === "duration") {
        keyName = "duration";
      }
      if (prop === "errors") {
        keyName = "errors";
      }
      if (prop === "processeds") {
        keyName = "processeds";
      }

      if (orderStr) {
        const orders = [];
        const orderItem = `${orderStr}:${keyName}`;
        orders.push(orderItem);
        this.pageParams.orders = orders;
      } else {
        this.pageParams.orders = [];
      }
      this.getTaskScheduleLogListReq();
    },

    getStateListReq() {
      getCodeList(19)
        .then((res) => {
          const result = parseResp(res);
          if (result.success) {
            this.stateList = result.data;
          } else {
            modelUi.msgError(result.message);
          }
        })
        .catch((err) => {
          modelUi.msgError(parseError(err));
        });
    },

    getTaskTypeListReq() {
      getCodeList(12)
        .then((res) => {
          const result = parseResp(res);
          if (result.success) {
            let typeList = result.data;
            typeList = typeList.filter(item => item.codeHelp === 'taskSchedule')
            console.log('typeList',typeList)
            this.taskTypeList =typeList;
          } else {
            modelUi.msgError(result.message);
          }
        })
        .catch((err) => {
          modelUi.msgError(parseError(err));
        });
    },

    selection() {
      return this.$refs.tableRows && this.$refs.tableRows.selection.length;
    },
    handleSelectAll() {
      const { isAllSelected } = this.$refs.tableRows.store.states;
      console.log(
        "selection==",
        this.$refs.tableRows.store.states.isAllSelected
      );
      if (isAllSelected) {
        const arr = [];
        selection.forEach((it) => {
          arr.push(...it.children);
        });
        this.selectChild(arr);
      } else {
        this.$refs.tableRows.clearSelection();
      }
    },
    // 获取列表数据
    getTaskScheduleLogListReq() {
      const {
        current,
        size,
        storeType,
        storeName,
        taskName,
        taskType,
        state,
        startTime,
        endTime,
        startProcessed,
        endProcessed,
        startError,
        endError,
        orders,
      } = this.pageParams;

      let startTimeStr = "";
      if (startTime && startTime.length > 0) {
        startTimeStr = `${startTime[0]},${startTime[1]}`;
      }

      let endTimeStr = "";
      if (endTime && endTime.length > 0) {
        endTimeStr = `${endTime[0]},${endTime[1]}`;
      }

      const startProcessedStr = startProcessed || "";
      const endProcessedStr = endProcessed || "";

      const startErrorStr = startError || "";
      const endErrorStr = endError || "";

      let processed = "";
      if (startProcessedStr && endProcessedStr) {
        processed = `${startProcessedStr},${endProcessedStr}`;
      }

      let erros = "";
      if (startErrorStr && endErrorStr) {
        erros = `${startErrorStr},${endErrorStr}`;
      }

      const data = {
        state,
        startTime: startTimeStr,
        endTime: endTimeStr,
        storeName,
        storeId: getStoreId(),
        storeType,
        taskType,
        taskName,
        processed,
        erros,
        current,
        size,
        orders,
      };
      this.getTaskScheduleLogList(data);
    },
    async getTaskScheduleLogList(data) {
      const res = await this.$Api.getTaskScheduleLogList(data);
      this.rows = [];
      this.leng = res.data.total;
      this.pageParams.total = res.data.total;
      this.tableData = res.data.records;
    },
    // 查询错误
    quertError(rows) {
      console.log('任务日志---查询失败数',rows)
      // const { message } = rows;
      rows.type='task'
      this.$refs.errorsLogDialogRef.openDialog(rows);
    },
  },
};
</script>

<style lang="scss" scoped>
// .storelistrom {
// padding: 0 10px 10px;
// }
.task_logs {
  .txtalein {
    padding-bottom: 20px;
    color: #000;
    font-size: 12px;
  }
  .table-cell__task-type {
    background-color: #f1f1f1;
    border-radius: 14px;
    padding: 2px 16px;
    display: inline-block;
  }

  .table-cell__task-type-icon {
    width: 16px;
    height: 16px;
  }

  .table-cell__task-type-name {
    color: #555555;
    font-size: 14px;
    margin-left: 5px;
  }
  .integration_link {
    color: #000 !important;
    border-radius: 20px;
  }
  .integation_serch {
    margin-left: 10px;
    cursor: pointer;
  }
  .integation_serch:hover {
    color: brown;
  }
  .intergation_picker {
    width: 200px;
  }
}
.icon {
  padding: 6px;
  cursor: pointer;
  .svg-icon {
    width: 23px;
    height: 23px;
  }
}

.text {
  padding: 0 15px;
  font-size: 12px;
}
.table_set {
  border-radius: 6px;
  padding: 15px 0 0;
  background-color: white;
  .select_filter {
    padding-bottom: 15px;
    .table_label_size {
      width: 160px;
      font-size: 16px;
    }
    .table_select {
      width: 214px;
    }
  }
  .icon {
    padding: 6px;
    cursor: pointer;
    .svg-icon {
      width: 23px;
      height: 23px;
    }
  }
  .b_pag {
    // padding: 0 35px;
  }
  .tb_checkbox {
    vertical-align: middle;
  }
}
</style>
